import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="mb-50">
      <div className="row flex justify-between mx-0 mt-5 my-3 mb-5">
        <div className="col-4">
          <h5 className="text-muted">Discover</h5>
          <ul className="navbar-nav">
            <li className="nav-item text-sm">Hot Hacks</li>
            <li className="nav-item text-sm">New Hacks</li>
            <li className="nav-item text-sm">Top 50 Hacks</li>
          </ul>
        </div>
        <div className="col-4">
          <h5 className="text-muted">Engage</h5>
          <ul className="navbar-nav">
            <li className="nav-item text-sm">Add Hack</li>
            <li className="nav-item text-sm">Send Feedback</li>
          </ul>
        </div>
        <div className="col-4">
          <h5 className="text-muted">More</h5>
          <ul className="navbar-nav">
            <li className="nav-item text-sm">Advertise with us</li>
            <li className="nav-item text-sm">MakeLanding</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;

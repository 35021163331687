import React, { useState } from "react";
import { IoChevronBack } from "react-icons/io5";
import { IoMdSend } from "react-icons/io";
import Layout from "../Layout/Layout";
import "./Form.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Spinner from "../Spinner/Spinner";

const Form = () => {
  // local state
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // submit form
  const handleSubmit = async () => {
    try {
      if (!description) {
        return toast.error("Please fill this field");
      }
      if (description.length < 5) {
        return toast.error(
          "Tip validation failed: text: Valuable tips are at least 5 characters long!"
        );
      }
      setLoading(true);
      const res = await axios.post(`/api/v1/hack/add-hack`, { description });
      if (res?.data?.success) {
        toast.success("Data saved successfully");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  return (
    <Layout>
      <div className="form-container my-3">
        <div className="mb-1">
          <button className="btn back-btn" onClick={() => navigate("/")}>
            <span>
              <IoChevronBack size={18} style={{ marginRight: "0.2rem" }} />
            </span>
            Back
          </button>
        </div>
        <div className="heading-text mb-5">
          <h1 className="fw-bold">Your #1 productivity hack?</h1>
        </div>
        <div className="mb-3">
          <label htmlFor="hack" className="form-label">
            Your Hack <span style={{ color: "#E29A9A" }}>*</span>
          </label>
          <textarea
            className="form-control"
            id="hack"
            placeholder="What helps you get things done?"
            rows={5}
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <button className="btn-primary w-100 p-3 pbtn" onClick={handleSubmit}>
            {" "}
            SEND <IoMdSend />
          </button>
        </div>
        <div className="text-center text-muted font-size-14">
          Your hack will appear in the /new list after 30 seconds
        </div>
      </div>
    </Layout>
  );
};

export default Form;

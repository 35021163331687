import React from "react";
import Layout from "../../components/Layout/Layout";
import Clients from "../../components/Clients/Clients";
import Ad from "../../assets/images/Ad.webp";
import Avatarpic from "../../assets/images/Avatarpic.webp";
import { MdArrowBackIos } from "react-icons/md";
import { FaArrowDown } from "react-icons/fa6";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import Collapsible from "react-collapsible";
import "./Advertise.css";
import { useNavigate } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";

const Advertise = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="mb-1 mt-2">
        <button className="btn back-btn" onClick={() => navigate("/")}>
          <span>
            <IoChevronBack size={18} style={{ marginRight: "0.2rem" }} />
          </span>
          Back
        </button>
      </div>
      <h1 className="heading">Advertise on 50 Hacks</h1>
      <div className="desc">
        <p>Get more visitors for your product or service.</p>&nbsp; 50 Hacks is
        the destination of thousands of visitors who are looking for solutions
        to get better every day.
      </div>
      <div class="flex flex-col items-center space-y-1 text-center">
        <p>
          <span className="timeline">1570</span>&nbsp; visitors on 50 Hacks
          (last 30 days)
        </p>
        <FaArrowDown />
        <p>
          <span className="timeline">5.5%</span>&nbsp; click-through rate on
          average
        </p>
        <FaArrowDown />
        <p>
          <span className="timeline">65 - 108</span>&nbsp; unique visitors on
          your site (30 days)
        </p>
        <span class="flex items-center gap-2 pt-2">
          <span class="relative inline-flex h-3 w-3">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
          </span>
          <span class="text-sm opacity-80">Live data</span>
        </span>
      </div>
      <div>
        <span className="span1">
          <span className="span2">
            <img className="adimg" alt="" src={Ad} />
          </span>
        </span>
        <h6 className="your">Your ad will be shown for 30 to 90 days:</h6>
        <ul className="list">
          <li>Fixed at the bottom of the main page</li>
          <li>At the bottom of each hack page</li>
          <li>In the top hacks list (top 5)</li>
        </ul>
      </div>
      <div>
        <h3 className="get">Get traffic on your site</h3>
        <div class="text-base-content/80 mb-3">
          We only show 1 ad at a time.
          <div class="flex items-center gap-1 font-semibold mb-3">
            <span class="text-base-content text-lg drop-shadow">💎</span>
            <span class="bluetext">Ad slot available now. This is rare!</span>
          </div>
        </div>
        <ul className="steps steps-vertical">
          <li class="step h-32">
            <a
              class="btn w-full pbtn text-white w-50 p-3 shadow"
              href="https://docs.google.com/forms/d/e/1FAIpQLSc-4kPOlmlAX75ghVhD2b97sCN_bXLUr243Q2VTVEDS8gXZIQ/viewform?usp=sf_link"
              target="_blank"
              rel="noreferrer"
            >
              Submit ad
            </a>
          </li>
          <li class="step">
            <div class="ml-2">
              <div class="adtext flex gap-2 mb-2">
                <p class="text-sm opacity-80 text-left mb-1">
                  Show your ad for
                </p>
                <select class="select">
                  <option value="30">30 days</option>
                  <option value="90" selected="default">
                    90 days
                  </option>
                </select>
              </div>
              <div class="flex gap-2 items-center flex-wrap">
                <a
                  class="btn w-full pbtn text-white w-50 p-3 shadow"
                  href="https://buy.stripe.com/8wM1862jSdK64zm288"
                  target="_blank"
                  rel="noreferrer"
                >
                  Pay $499&nbsp;
                  <span className="money">$597</span>
                </a>
              </div>
            </div>
          </li>
        </ul>
        <p class="text-sm opacity-80 mt-4">
          Your product or service must have a publically accessible URL, help
          people achieve their goals and should not promote NSFW or violent
          content.
        </p>
      </div>

      <Clients />

      <section>
        {/* <ul>
          <li>
          <button class="flex faqb">
            <svg class="w-3 h-3 fill-current text-accent flex-shrink-0 mr-6 -ml-10" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <rect y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out undefined"></rect>
              <rect y="7" width="16" height="2" rx="1" class="transform origin-center rotate-90 transition duration-200 ease-out undefined"></rect>
            </svg>
            <span>What type of audience is on 50 Hacks?</span>
          </button>
          <div class="text-base-content overflow-hidden transition-all duration-300 ease-in-out" className="max-height: 0px; opacity: 0;">
            <div class="pb-3 opacity-80 text-sm">People who are into self-improvement and read books like Atomic Habits. Entrepreneurs, developers, designers and working-from-home people. A typical user follows Naval on Twitter.
            </div>
          </div>
          </li>
          <li>
          <button class="flex items-center w-full font-semibold text-left py-3 border-t border-base-300/50">
          <svg class="w-3 h-3 fill-current text-accent flex-shrink-0 mr-6 -ml-10" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <rect y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out undefined"></rect>
          <rect y="7" width="16" height="2" rx="1" class="transform origin-center rotate-90 transition duration-200 ease-out undefined"></rect>
          </svg>
          <span>For how long and where will my ad be shown?</span>
          </button>
          <div class="text-base-content overflow-hidden transition-all duration-300 ease-in-out" className="max-height: 0px; opacity: 0;">
          <div class="pb-3 opacity-80 text-sm">Based on the package your purchase, your ad will be shown for 30 to 90 days. Your ad will be visible in different areas on 50 Hacks. We only advertise 1 product at a time so yours will stand out!</div>
          </div>
          </li>
          <li>
          <button class="flex items-center w-full font-semibold text-left py-3 border-t border-base-300/50">
          <svg class="w-3 h-3 fill-current text-accent flex-shrink-0 mr-6 -ml-10" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <rect y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out undefined"></rect>
          <rect y="7" width="16" height="2" rx="1" class="transform origin-center rotate-90 transition duration-200 ease-out undefined"></rect>
          </svg>
          <span>Why should I wait for my ad to be live?</span>
          </button>
          <div class="text-base-content overflow-hidden transition-all duration-300 ease-in-out" className="max-height: 0px; opacity: 0;">
          <div class="pb-3 opacity-80 text-sm">We only advertise 1 product at a time so yours stand out. Hence, there is a waiting line. You can lock your spot today by submitting your ad and choosing a plan!</div>
          </div>
          </li>
          <li>
          <button class="flex items-center w-full font-semibold text-left py-3 border-t border-base-300/50">
          <svg class="w-3 h-3 fill-current text-accent flex-shrink-0 mr-6 -ml-10" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <rect y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out undefined"></rect>
          <rect y="7" width="16" height="2" rx="1" class="transform origin-center rotate-90 transition duration-200 ease-out undefined"></rect>
          </svg>
          <span>Do you offer discounts?</span>
          </button>
          <div class="text-base-content overflow-hidden transition-all duration-300 ease-in-out" className="max-height: 0px; opacity: 0;">
            <div class="pb-3 opacity-80 text-sm">The current price is early-bird as we launched a few months ago. It's likely to go up in the future so save your spot today!</div>
          </div>
          </li>
          <li>
          <button class="flex items-center w-full font-semibold text-left py-3 border-t border-base-300/50">
            <svg class="w-3 h-3 fill-current text-accent flex-shrink-0 mr-6 -ml-10" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <rect y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out undefined"></rect>
              <rect y="7" width="16" height="2" rx="1" class="transform origin-center rotate-90 transition duration-200 ease-out undefined"></rect>
            </svg>
            <span>Do you offer refunds?</span>
          </button>
          <div class="text-base-content overflow-hidden transition-all duration-300 ease-in-out" className="max-height: 0px; opacity: 0;">
            <div class="pb-3 opacity-80 text-sm">If your product is not a good fit for 50 Hacks we will refund you, no questions asked. We offer refunds if we experience a sudden traffic drop (never happened before but we never know on the internet).</div>
          </div>
          </li>
        </ul> */}
        <Collapsible
          trigger={[
            <AiOutlinePlus style={{ marginRight: "1rem", color: "#ea5234" }} />,
            "What type of audience is on 50 Hacks?",
          ]}
          transitionTime={100}
        >
          <p>
            People who are into self-improvement and read books like Atomic
            Habits. Entrepreneurs, developers, designers and working-from-home
            people. A typical user follows Naval on Twitter.
          </p>
        </Collapsible>
        <Collapsible
          trigger={[
            <AiOutlinePlus style={{ marginRight: "1rem", color: "#ea5234" }} />,
            "For how long and where will be my ad shown?",
          ]}
          transitionTime={100}
        >
          <p>
            Based on the package your purchase, your ad will be shown for 30 to
            90 days. Your ad will be visible in different areas on 50 Hacks. We
            only advertise 1 product at a time so yours will stand out!
          </p>
        </Collapsible>
        <Collapsible
          trigger={[
            <AiOutlinePlus style={{ marginRight: "1rem", color: "#ea5234" }} />,
            "Why should I wait for my ad to be live?",
          ]}
          transitionTime={100}
        >
          <p>
            We only advertise 1 product at a time so yours stand out. Hence,
            there is a waiting line. You can lock your spot today by submitting
            your ad and choosing a plan!
          </p>
        </Collapsible>
        <Collapsible
          trigger={[
            <AiOutlinePlus style={{ marginRight: "1rem", color: "#ea5234" }} />,
            "Do you offer discounts?",
          ]}
          transitionTime={100}
        >
          <p>
            The current price is early-bird as we launched a few months ago.
            It's likely to go up in the future so save your spot today!
          </p>
        </Collapsible>
        <Collapsible
          trigger={[
            <AiOutlinePlus style={{ marginRight: "1rem", color: "#ea5234" }} />,
            "Do you offer refunds?",
          ]}
          transitionTime={100}
        >
          <p>
            If your product is not a good fit for 50 Hacks we will refund you,
            no questions asked. We offer refunds if we experience a sudden
            traffic drop (never happened before but we never know on the
            internet).
          </p>
        </Collapsible>
      </section>
      <section>
        <div class="flex items-center mt-4">
          <div class="avatar">
            {/* <div class="relative w-14 rounded-full">
              <span className="avatardiv">
                <img alt="Marc Lou" srcset={Avatarpic} className="avatarpic" />
              </span>
            </div> */}
          </div>
          <div>
            Hey I'm Marc 👋 the founder of 50 Hacks. If you have any questions
            or suggestions, &nbsp;
            <a
              className="tlink"
              href="https://twitter.com/marc_louvion"
              target="_blank"
            >
              reach out on Twitter
            </a>
            .
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Advertise;

import React from "react";
import { AiOutlineTwitter } from "react-icons/ai";
import moment from "moment";

const Card = ({ hacks }) => {
  return (
    <>
      {hacks?.map((hack, i) => (
        <a className="card text-decoration-none mb-3 shadow" href="#">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">
                    {i + 1}.
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">{hack?.description}</div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      {moment(hack.createdAt).startOf("hour").fromNow()} | 0
                      comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">1</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>
      ))}
    </>
  );
};

export default Card;

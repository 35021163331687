import React from "react";
import Layout from "../../components/Layout/Layout";
import Card from "../../assets/images/card-img.svg";
import Clients from "../../components/Clients/Clients";
import { Link } from "react-router-dom";
import "../../pages/Home/Home.css";
import { AiOutlineTwitter } from "react-icons/ai";
import { BiPlusCircle } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { BsStars, BsFillBarChartFill } from "react-icons/bs";
import { HiFire } from "react-icons/hi";

const Top = () => {
  return (
    <Layout>
      <div className="text-center">
        <h2 className="mt-5 mb-3 heading">
          Top 50 productivity hacks chosen by internet and you
        </h2>
        <p className="mx-auto mt-4" style={{ width: "85%" }}>
          <span className="text__underline">Upvote & Comment</span> your
          favorites. <span className="text__underline">Write</span> your best.
          No account needed!
        </p>
      </div>

      <div className="my-5">
        <a
          href="https://www.producthunt.com/products/50-hacks?utm_source=badge-top-post-badge&utm_medium=badge#50-hacks"
          className="d-flex justify-content-center my-4"
          target="_blank"
        >
          <img src={Card} alt="" className="imgBlock" />
        </a>
      </div>
      <div className="tab">
        <Link className="link" to="/">
        <HiFire /> Hot
        </Link>
        <Link className="link" to="/new">
        <BsStars /> New
        </Link>
        <Link className="link" to="/top">
        <BsFillBarChartFill /> Top
        </Link>
      </div>
      {/* Poromotion card container starts */}
      <div>
        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">1.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">2.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">3.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <Link
          to="/form"
          className="btn w-full pbtn text-white w-100 p-3 shadow"
        >
          <div className="d-flex align-items-center gap-1">
            <div className="mx-1">
              <BiPlusCircle size={24} />
            </div>
            <div>
              <div>Add your best productivity hack?</div>
              <div className="text-small">No account needed!</div>
            </div>
          </div>
        </Link>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">4.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">5.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">6.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>
      
        <a className="card text-decoration-none mb-3 shadow" href="#hot">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">7.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">8.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">9.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">10.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">11.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">12.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">13.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">14.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <Link
          to="/form"
          className="btn w-full pbtn text-white w-100 p-3 shadow"
        >
          <div className="d-flex align-items-center gap-1">
            <div className="mx-1">
              <BiPlusCircle size={24} />
            </div>
            <div>
              <div>Add your best productivity hack?</div>
              <div className="text-small">No account needed!</div>
            </div>
          </div>
        </Link>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">15.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">16.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">17.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>
      
        <a className="card text-decoration-none mb-3 shadow" href="#hot">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">18.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">19.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">20.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">21.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">22.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">23.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">24.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">25.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">26.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">27.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">28.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>
      
        <a className="card text-decoration-none mb-3 shadow" href="#hot">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">29.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">30.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">31.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">32.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">33.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">34.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">35.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">36.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">37.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">38.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">39.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <Link
          to="/form"
          className="btn w-full pbtn text-white w-100 p-3 shadow"
        >
          <div className="d-flex align-items-center gap-1">
            <div className="mx-1">
              <BiPlusCircle size={24} />
            </div>
            <div>
              <div>Add your best productivity hack?</div>
              <div className="text-small">No account needed!</div>
            </div>
          </div>
        </Link>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">40.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>
      
        <a className="card text-decoration-none mb-3 shadow" href="#hot">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">41.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">42.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">43.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">44.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">45.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">46.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">47.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">48.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">49.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">50.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut, quidem.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">6</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div className=" d-flex flex-column justify-content-center align-items-center my-5">
        <p className="mb-3 text-muted">Looks like you hit rock bottom...</p>
        <button className="hack-btn btn d-flex btn__primary">
          <span>
            <FiPlus size={20} />
          </span>
          <div className="mx-1 btn-text">Add Hack</div>
        </button>
      </div>

      <Clients />

      <div className="promotion-block mt-5 mb-5">
        <h4 className="fontWeight-700 mb-4">Promote your product or service</h4>
        <p className="mb-4">
          50 Hacks is the destination of thousands of visitors who are looking
          for solutions to get better every day.
        </p>
        <button className="btn text-uppercase btn__primary">
          Advertise On 50 Hacks
        </button>
      </div>

      <div className="question-block mt-5">
        <h4 className="fontWeight-700 mt-3 mb-4">What is 50 Hacks?</h4>
        <p>
          50 Hacks is a crowdsourced list of the 50 best productivity hacks.
          Anyone can write tips that help them get things done—no account
          needed. The internet upvotes the best ones. <br /> You get a curated
          list of the top productivity tips! <br />
          <br />
          If you have any feedback, please reach out to me (Marc, the maker of
          50 Hacks) on{" "}
          <a
            className="text-decoration-none text__underline"
            style={{ color: "#D03516" }}
          >
            Twitter
          </a>
          .
        </p>
      </div>
    </Layout>
  );
};

export default Top;

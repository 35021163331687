import "./App.css";
import { NavLink, Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home/Home";
import Advertise from "./pages/Advertise/Advertise";
import New from "./pages/New/New";
import Top from "./pages/Top/Top";
import { FcLike } from "react-icons/fc";
import { RxCross2 } from "react-icons/rx";
import WorkBook from "./assets/images/workbookpdfLogo061c.png";
import Form from "./components/Form/Form";
import { useState } from "react";
import { Toaster } from "react-hot-toast";

function App() {
  const [toggle, setIsToggle] = useState(false);

  // function to close the status bar
  const handleCloseBar = () => {
    setIsToggle(!toggle);
  };
  return (
    <>
      <BrowserRouter>
        <Toaster position="top-right" />
        <div className="container ">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/new-hack" element={<Form />} />
            <Route path="/advertise" element={<Advertise />} />
            <Route path="/new" element={<New />} />
            <Route path="/Top" element={<Top />} />
          </Routes>
        </div>
        <div
          className={`p-3 mt-5 border-top-2 position-fixed bottom-0 w-100 z-index-1 footer_bottom ${
            toggle && "d-none"
          }`}
        >
          <div className="d-flex justify-content-center align-items-center">
            <div className="img">
              <img
                src={WorkBook}
                alt=""
                style={{ width: "35px", marginRight: "0.5rem" }}
              />
            </div>
            <div className="d-flex flex-column">
              <div className="heading-block fw-bold">
                Language workbooks you'll <FcLike size={22} />
              </div>
              <div className="text-block">Learn a new language in no time!</div>
            </div>
            <div className="cross_icon">
              <RxCross2 size={22} onClick={handleCloseBar} />
            </div>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;

import React from "react";
import "./Header.css";
import { FiPlus } from "react-icons/fi";
import user from "../../assets/images/marc38e5.png";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <nav className="head-container">
      <div className="header">
        <div className="nav-left">
          <button className="hack-btn">
            <span>
              <FiPlus size={18} />
            </span>
            <Link
              to="/new-hack"
              className="ml-1 btn-text text-decoration-none text-white"
            >
              New <br />
              Hack
            </Link>
          </button>
        </div>
        <div className="nav-center">
          <Link to="/" className="logo text-decoration-none text-black">
            <span className="left">🤩</span>
            <span className="center logo-text">50 Hacks</span>
            <span className="right">💩</span>
          </Link>
        </div>
        <div className="nav-end">
          <a
            className="user-logo"
            href="https://twitter.com/marc_louvion"
            target="_blank"
          >
            <div className="avatar">
              <img src={user} alt="user" />
            </div>
            <div className="text-uppercase">
              By <br />
              <span className="text-orange text__underline">Marc</span>
            </div>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Header;

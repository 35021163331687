import React from "react";
import Layout from "../../components/Layout/Layout";
import Card from "../../assets/images/card-img.svg";
import Clients from "../../components/Clients/Clients";
import { Link } from "react-router-dom";
import "../../pages/Home/Home.css";
import { AiOutlineTwitter } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import { BsStars, BsFillBarChartFill } from "react-icons/bs";
import { HiFire } from "react-icons/hi";


const New = () => {
  return (
    <Layout>
      <div className="text-center">
        <h2 className="mt-5 mb-3 heading">
          Top 50 productivity hacks chosen by internet and you
        </h2>
        <p className="mx-auto mt-4" style={{ width: "85%" }}>
          <span className="text__underline">Upvote & Comment</span> your
          favorites. <span className="text__underline">Write</span> your best.
          No account needed!
        </p>
      </div>

      <div className="my-5">
        <a
          href="https://www.producthunt.com/products/50-hacks?utm_source=badge-top-post-badge&utm_medium=badge#50-hacks"
          className="d-flex justify-content-center my-4"
          target="_blank"
        >
          <img src={Card} alt="" className="imgBlock" />
        </a>
      </div>
      <div className="tab">
        <Link className="link" to="/">
        <HiFire /> Hot
        </Link>
        <Link className="link" to="/new">
        <BsStars /> New
        </Link>
        <Link className="link" to="/top">
        <BsFillBarChartFill /> Top
        </Link>
      </div>
      {/* Poromotion card container starts */}
      <div>
        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">1.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                    Stretching helps better than coffee to reinvigorate your mind.
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">1</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">2.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                    My go-to tool for creating my resumes is CV Maker 
                    AI https://cvmakerai.com it makes the whole process seamless. 
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">1</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a className="card text-decoration-none mb-3 shadow" href="">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="left">
                <div className="d-flex">
                  <div className="count mx-1 text-muted fw-semibold">3.</div>
                  <div className="d-flex flex-column gap-2">
                    <div className="cardtext text">
                    Wear organic cotton to reduce skin irritation, 
                    have more breathability, no exposure to chemicals, 
                    and to feel better about the planet!
                    </div>
                    <div className="usertext">
                      By{" "}
                      <a className="user-icon">
                        <AiOutlineTwitter size={16} />{" "}
                        <span className="username">davidcr010</span>
                      </a>{" "}
                      18 days ago | 0 comment | report | share
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="d-flex flex-column align-items-center">
                  <a className="likes">🤩</a>
                  <div className="likeCount fw-bold">1</div>
                  <a className="dislike">💩</a>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div className=" d-flex flex-column justify-content-center align-items-center my-5">
        <p className="mb-3 text-muted">Looks like you hit rock bottom...</p>
        <button className="hack-btn btn d-flex btn__primary">
          <span>
            <FiPlus size={20} />
          </span>
          <div className="mx-1 btn-text">Add Hack</div>
        </button>
      </div>

      <Clients />

      <div className="promotion-block mt-5 mb-5">
        <h4 className="fontWeight-700 mb-4">Promote your product or service</h4>
        <p className="mb-4">
          50 Hacks is the destination of thousands of visitors who are looking
          for solutions to get better every day.
        </p>
        <button className="btn text-uppercase btn__primary">
          Advertise On 50 Hacks
        </button>
      </div>

      <div className="question-block mt-5">
        <h4 className="fontWeight-700 mt-3 mb-4">What is 50 Hacks?</h4>
        <p>
          50 Hacks is a crowdsourced list of the 50 best productivity hacks.
          Anyone can write tips that help them get things done—no account
          needed. The internet upvotes the best ones. <br /> You get a curated
          list of the top productivity tips! <br />
          <br />
          If you have any feedback, please reach out to me (Marc, the maker of
          50 Hacks) on{" "}
          <a
            className="text-decoration-none text__underline"
            style={{ color: "#D03516" }}
          >
            Twitter
          </a>
          .
        </p>
      </div>
    </Layout>
  );
};

export default New;

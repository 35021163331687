import React from "react";
import ProductHunt from "../../assets/images/logo-product-hunt00b8.png";
import Hustle from "../../assets/images/logo-the-hustle98e5.png";
import Medium from "../../assets/images/logo-medium32b0.png";
import Tiktok from "../../assets/images/logo-tiktok0dbe.png";
import Instagram from "../../assets/images/logo-instagrama49f.png";
import "./Clients.css";

const Clients = () => {
  return (
    <div className="client-container my-5 ">
      <div className="clients-section d-flex justify-content-between mx-auto align-items-start gap-2">
        <div className=" fontSize-10 w-10">As seen in</div>
        <div className="clientImages">
          <img
            src={ProductHunt}
            alt="the-product-hunt-logo"
            style={{
              width: "130px",
              marginRight: "0.5rem",
              marginBottom: "1rem",
            }}
          />
          <img
            src={Hustle}
            alt="the-hustle-logo"
            style={{
              width: "130px",
              marginRight: "0.5rem",
              marginBottom: "1rem",
            }}
          />
          <img
            src={Medium}
            alt="the-medium-logo"
            style={{
              width: "130px",
              marginRight: "0.5rem",
              marginBottom: "1rem",
            }}
          />
          <img
            src={Tiktok}
            alt="tiktok-logo"
            style={{
              width: "130px",
              marginRight: "0.5rem",
              marginBottom: "1rem",
            }}
          />
          <img
            src={Instagram}
            alt="instagram-logo"
            style={{
              width: "130px",
              marginRight: "0.5rem",
              marginBottom: "1rem",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Clients;

import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Card from "../../components/Card/Card";
import Clients from "../../components/Clients/Clients";
import Spinner from "../../components/Spinner/Spinner";
import "../../pages/Home/Home.css";
import { AiFillStar } from "react-icons/ai";
import { BiPlusCircle } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { BsStars, BsFillBarChartFill } from "react-icons/bs";
import { HiFire } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const Home = () => {
  const navigate = useNavigate();
  const [hacks, setHacks] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to fetch hacks
  // const handleFetchHacks = async () => {
  //   try {
  //     setLoading(true);
  //     const { data } = await axios.get(`/api/v1/hack/get-hacks`);
  //     setHacks(data?.hacks);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error(error?.message);
  //   }
  // };

  // useEffect(() => {
  //   handleFetchHacks();
  // }, []);
  return (
    <Layout>
      <div className="text-center">
        <h2 className="mt-5 mb-3 heading">
          Top 50 productivity hacks chosen by internet and you
        </h2>
        <p className="mx-auto mt-4" style={{ width: "85%" }}>
          <span className="text__underline fw-semibold darkColor">
            Upvote & Comment
          </span>{" "}
          your favorites.{" "}
          <Link
            to={"/new-hack"}
            className="text-decoration-none text-dark fw-semibold"
          >
            <span className="text__underline darkColor">Write</span>
          </Link>{" "}
          your best. No account needed!
        </p>
      </div>

      <div className="my-5">
        <a
          href="https://www.producthunt.com/products/50-hacks?utm_source=badge-top-post-badge&utm_medium=badge#50-hacks"
          className="d-flex justify-content-center my-4"
          target="_blank"
        >
          <img src={Card} alt="" className="imgBlock" />
        </a>
      </div>
      <div className="tab mb-4">
        <Link className="link active" to="/">
          <HiFire /> Hot
        </Link>
        <Link className="link" to="/new">
          <BsStars /> New
        </Link>
        <Link className="link" to="/top">
          <BsFillBarChartFill /> Top
        </Link>
      </div>
      {/* Poromotion card container starts */}
      <div>
        {/* {loading ? <Spinner /> : <><Card hacks={hacks} /></>} */}

        <Link
          to="/new-hack"
          className="btn w-full pbtn text-white w-100 p-3 shadow"
        >
          <div className="d-flex align-items-center gap-1">
            <div className="mx-1">
              <BiPlusCircle size={24} />
            </div>
            <div>
              <div>Add your best productivity hack?</div>
              <div className="text-small">No account needed!</div>
            </div>
          </div>
        </Link>
      </div>

      <div className="sponsor d-flex justify-content-center align-items-center my-3 p-2 rounded bg__sponsor">
        <div className="icon mx-2">
          <AiFillStar size={20} />
        </div>
        <div className="text-uppercase fw-semibold">Sponsor us</div>
      </div>

      <div>
        <Card hacks={hacks} />
      </div>

      <div className=" d-flex flex-column justify-content-center align-items-center my-5">
        <p className="mb-3 text-muted">Looks like you hit rock bottom...</p>
        <button className="hack-btn btn d-flex btn__primary">
          <span>
            <FiPlus size={20} />
          </span>
          <Link
            to="/new-hack"
            className="mx-1 btn-text text-decoration-none text-white"
          >
            Add Hack
          </Link>
        </button>
      </div>

      <Clients />

      <div className="promotion-block mt-5 mb-5">
        <h4 className="fontWeight-700 mb-4">Promote your product or service</h4>
        <p className="mb-4">
          50 Hacks is the destination of thousands of visitors who are looking
          for solutions to get better every day.
        </p>
        <button
          className="btn text-uppercase btn__primary"
          onClick={() => navigate("/advertise")}
        >
          Advertise On 50 Hacks
        </button>
      </div>

      <div className="question-block mt-5">
        <h4 className="fontWeight-700 mt-3 mb-4">What is 50 Hacks?</h4>
        <p>
          50 Hacks is a crowdsourced list of the 50 best productivity hacks.
          Anyone can write tips that help them get things done—no account
          needed. The internet upvotes the best ones. <br /> You get a curated
          list of the top productivity tips! <br />
          <br />
          If you have any feedback, please reach out to me (Marc, the maker of
          50 Hacks) on{" "}
          <a
            className="text-decoration-none text__underline"
            style={{ color: "#D03516" }}
          >
            Twitter
          </a>
          .
        </p>
      </div>
    </Layout>
  );
};

export default Home;
